import React from 'react'
import './style.scss'
import {Link} from 'gatsby'

import Img from 'gatsby-image'
import Intro from '../../../../../animations/Intro'
import { useInView } from 'react-intersection-observer'

function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {
  const [refInView, ioInView] = useInView({ triggerOnce: true })

	return (
		<div ref={refInView} className="master-wrap x1 t">
			<div className="intro fs-85"><p className='h6' dangerouslySetInnerHTML={{__html: field[0]}}/></div>
      <div className='schools-wrap'>
			{ img[0] ?
				<Link className="wrap" to={field[2]} title={field[1]}>
          <Intro visible={ioInView} in={{ fadeBottom: 500 }} delayIn={125} mounted={true} stay={true} className="full-width">
            <div className="aspect-ratio">
              <Img fluid={img[0]}
                imgStyle={{objectFit: 'cover'}}
                objectPosition='50% 50%'
                className='img1'
								alt=''
              />
              <div className="field">
                <h2 className="text h4">{field[1]}</h2>
                <div className="icon"></div>
              </div>
            </div>
          </Intro>
			  </Link>
			: null }
			{ img[1] ?
				<Link className="wrap" to={field[4]}  title={field[3]}>
         <Intro visible={ioInView} in={{ fadeBottom: 500 }} delayIn={250} mounted={true} stay={true} className="full-width">
            <div className="aspect-ratio">
              <Img fluid={img[1]}
                imgStyle={{objectFit: 'cover'}}
                objectPosition='50% 50%'
                className='img1'
								alt=''
              />
              <div className="field">
                <h2 className="text h4">{field[3]}</h2>
                <div className="icon"></div>
              </div>
            </div>
          </Intro>
				</Link>
			: null }
			{ img[2] ?
				<Link className="wrap" to={field[6]}  title={field[5]}>
          <Intro visible={ioInView} in={{ fadeBottom: 500 }} delayIn={375} mounted={true} stay={true} className="full-width">
            <div className="aspect-ratio">
              <Img fluid={img[2]}
                imgStyle={{objectFit: 'cover'}}
                objectPosition='50% 50%'
                className='img1'
								alt=''
              />
              <div className="field">
                <h2 className="text h4">{field[5]}</h2>
                <div className="icon"></div>
              </div>
            </div>
          </Intro>
				</Link>
			: null }
			{ img[3] ?
				<Link className="wrap" to={field[8]}  title={field[7]}>
          <Intro visible={ioInView} in={{ fadeBottom: 500 }} delayIn={500} mounted={true} stay={true} className="full-width">
            <div className="aspect-ratio">
              <Img fluid={img[3]}
                imgStyle={{objectFit: 'cover'}}
                objectPosition='50% 50%'
                className='img1'
								alt=''
              />
              <div className="field">
                <h2 className="text h4">{field[7]}</h2>
                <div className="icon"></div>
              </div>
            </div>
          </Intro>
				</Link>
			: null }
      </div>
		</div>
	)
}

export default CustomBlock