import React, {useContext} from 'react'

import './style.scss'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Img from 'gatsby-image'
import FSLightBoxContext from '../../context/FSLightBoxContext'

function Gallery(props) {
	const lightBoxContext = useContext(FSLightBoxContext)

	const slides = props.gallery
	const caption = props.caption

	function thumbClicked() {

		let lightboxSlidesArray = []
		let lightboxCaptionsArray = []

		slides.map((slide, i) => {
			lightboxSlidesArray.push(slide.localFile.url)
			lightboxCaptionsArray.push(caption)
		})
 
		lightBoxContext.setSources(() => lightboxSlidesArray)
		lightBoxContext.setCaptions(() => lightboxCaptionsArray)
		
		setTimeout(() => {
        lightBoxContext.setToggler((toggle) => !toggle)
    }, 50)
  }

	return (
		<div className='aspect-ratio' aria-label='Open Modal' role='button' tabIndex={0} onClick={() => thumbClicked()}>
			<Img fluid={slides?.[0]?.localFile?.childImageSharp?.fluid}		
				imgStyle={{objectFit: 'cover'}}
				objectPosition='50% 50%'
				className='image-main'
				alt=''
			/>  
		</div>
  )
}

export default Gallery