import React, { useContext } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../../animations/Intro'
import ModalContext from '../../../../../context/ModalContext'

function Tiles({data}) {

	const [io, ioInView] = useInView({ triggerOnce: true })
	const modalContext = useContext(ModalContext)
	  // Images	
		const images = data.asset?.filter(obj => obj.__typename === 'Academic_Pagebuilder_Sections_Content_Block_Custom_Asset_Image')
    .map((node) => {
      return node.assetImg?.srcSet
    })

  // Fields	
  const fields = data.asset?.filter(obj =>  obj.fieldGroupName === 'Academic_Pagebuilder_Sections_Content_Block_Custom_Asset_Field')
    .map((node) => {
        return {name: node.assetField, slug: node.assetId}
    })

	function openModal(string) {
		modalContext.setDataAttr(string)
		modalContext.setActive(true)
	}

	return (
		<div ref={io} id="custom-tiles" className="c5 t">
			<div className='flex'>
				{images?.map((item, i) => {
					return (
						fields?.[i]?.slug ?
							<a key={i} onClick={() => openModal(fields?.[i]?.slug)} aria-label='Open Modal'  target='_blank' rel='noopener noreferrer' className='single-person' >
								<Intro visible={ioInView} in={{ fadeRight: 500 }} delayIn={250 + 75 * i} mounted={true} stay={true} >
									<div style={{ paddingBottom: "84%" }} className='aspect-ratio img'>
									<img srcSet={ item} alt=''/>

										<div className='copy'>
											<div className='heading padd-2'>
												<h4 className='h3'>{fields?.[i]?.name}</h4>
											</div>
										</div>
									</div>
								</Intro>
							</a>
							:
							<div key={i} className='single-person' >
								<Intro visible={ioInView} in={{ fadeRight: 500 }} delayIn={250 + 75 * i} mounted={true} stay={true} >
									<div style={{ paddingBottom: "84%" }} className='aspect-ratio img'>
									<img srcSet={ item} alt=''/>

										<div className='copy'>
											<div className='heading padd-2'>
												<h4 className='h3'>{fields?.[i]?.name}</h4>
											</div>
										</div>
									</div>
								</Intro>
							</div>
					)
				})}
			</div>
		</div>
	)
}

export default Tiles