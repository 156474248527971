import React from 'react'
import './style.scss'
import Img from 'gatsby-image'

function CustomBlock(props) {

  const itemsMap = props.images.map( (img, i) => {
    let wysiwyg = props.wysiwygs[i]
    let title  = props.fields[i]

    return (
      <div key={i} className="span-4 span-12-tablet single-item c4">  
        <div className="container">
          <div className="img-container">
            <Img fluid={img}
              imgStyle={{objectFit: 'cover'}}
              objectPosition='50% 50%'
              className="image"
							alt=''
            />
            <h3 className="title">{title}</h3>
            <div className="content-container">
              <div className="wysiwyg-inject" dangerouslySetInnerHTML={{__html: wysiwyg}} />  
            </div>
          </div>
        </div>
      </div>
    )
  })

      

	return (
        <div className="master-wrap grid-12">
          {itemsMap}
        </div>
	)
}

export default CustomBlock