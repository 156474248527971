import React, { useEffect } from 'react'
import './style.scss'
import Img from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../../animations/Intro'
import { Link } from 'gatsby'

function CustomBlock({ fields: field, images: image }) {

  const [refInView, ioInView] = useInView({ triggerOnce: true })

  return (
    <div className='custom-hero-next-content'>
      <div ref={refInView} className='flex-12 x1 t padd'>
        <div className='left'>
          <Intro visible={ioInView} in={{ fadeLeft: 700 }} delayIn={500} mounted={true} stay={true} className="full-width">
            <div style={{ paddingBottom: "112%" }} className="aspect-ratio">
              <Img fluid={image?.[0]}
                imgStyle={{ objectFit: 'cover' }}
                objectPosition='50% 50%'
                className='Hero Image'
								alt=''
              />
            </div>
          </Intro>
        </div>
        <div className='right'>
          <Intro visible={ioInView} in={{ fadeRight: 700 }} delayIn={625} mounted={true} stay={true} className="full-width">
            <div style={{ paddingBottom: "40%" }} className="aspect-ratio">
              <Img fluid={image?.[1]}
                imgStyle={{ objectFit: 'contain' }}
                objectPosition='50% 50%'
                className='Hero Text'
								alt=''
              />
            </div>
          </Intro>
          <Intro visible={ioInView} in={{ fadeBottom: 700 }} delayIn={750} mounted={true} stay={true} className="headings-container">
            <p className='h2 italic'>
              To be a leader
            </p>
            <p className='h2 italic'>
              To earn a diploma
            </p>
            <p className='h2 italic'>
              To save a life
            </p>
            <p className='h2 italic'>
              To teach the future
            </p>
            <p className='h2 italic'>
              To change the world
            </p>
            <div className='text-bar'>
              <h1 className='h2 italic'>
                To make a difference
              </h1>
            </div>
            <div className='button-bar'>
              <div className='h2 italic'>
              <Link to="#" className='button'>Enroll Today<span className="btn-arrow"></span></Link >
              </div>
            </div>

          </Intro>
        </div>
      </div>
    </div>
  )
}

export default CustomBlock