import React from 'react'
import './style.scss'
import Img from "gatsby-image"
import { Link } from 'gatsby'
import Intro from '../../../../../animations/Intro'
import { useInView } from 'react-intersection-observer'

function CustomBlock({ images: image }) {

  const [refInView, ioInView] = useInView({ triggerOnce: true })

  return (
    <div ref={refInView} className='custom-explore-programs c5 grid-12 padd padd-top-2 padd-bottom-2'>

      <div style={{ zIndex: "100" }} className='span-5 span-12-tablet title-with-logo'>
        <div className='logo-img'>
          <Intro visible={ioInView} in={{ fade: 500 }} delayIn={250} mounted={true} stay={true} className="full-width">
            <div className='aspect-ratio square'>
              <Img fluid={image?.[0]}
                imgStyle={{ objectFit: 'cover' }}
                objectPosition='50% 50%'
                className='Hero Text'
                alt='Next Logo'
              />
            </div>
          </Intro>
        </div>
        <div className='intro outline'>
          <Intro visible={ioInView} in={{ fade: 500 }} delayIn={500} mounted={true} stay={true} className="of-visible">
            <h1>Explore <br />
              our<br />
              Programs</h1>
          </Intro>
        </div>
      </div>

      <Intro visible={ioInView} in={{ fade: 500 }} delayIn={500} mounted={true} stay={true} className="span-7 span-12-tablet">
        <div style={{ zIndex: "100" }} className='right-bg padd'>
          <Intro visible={ioInView} in={{ fadeLeft: 500 }} delayIn={750} mounted={true} stay={true} className="">
            <div className='single-school padd'>
              <div className='img'>
                <div className='aspect-ratio'>
                  <Img fluid={image?.[1]}
                    imgStyle={{ objectFit: 'cover' }}
                    objectPosition='50% 50%'
                    className='Hero Text'
                    alt='School of Education'
                  />
                </div>
              </div>
              <div className='content padd padd-top-off'>
                <h4>School of Education</h4>
                <p className='fs-85'>Established in 2006 offering diploma programs for the Florida Teacher Certification. The School of Education is currently offering the Master of Educational Administration program.</p>
                <div className="links-container fs-85">
                  <Link to="/archives/academic/master-of-education-in-educational-administration" className='button'>MASTER OF EDUCATION IN EDUCATIONAL ADMINISTRATION<span className="btn-arrow"></span></Link >
                </div>
              </div>
            </div>
          </Intro>

          <Intro visible={ioInView} in={{ fadeLeft: 500 }} delayIn={825} mounted={true} stay={true} className="">
            <div className='single-school padd'>
              <div className='img'>
                <div className='aspect-ratio'>
                  <Img fluid={image?.[2]}
                    imgStyle={{ objectFit: 'cover' }}
                    objectPosition='50% 50%'
                    className='Hero Text'
                    alt='School of Business'
                  />
                </div>
              </div>
              <div className='content padd padd-top-off'>
                <h4>School of Business</h4>
                <p className='fs-85'>Established in 2013, offering diploma and associate degree accounting programs. Currently the School of Business is offering the MBA program.</p>
                <div className="links-container fs-85">
                  <Link to="/archives/academic/master-of-business-administration" className='button'>MASTER OF BUSINESS ADMINISTRATION<span className="btn-arrow"></span></Link >
                </div>
              </div>
            </div>
          </Intro>

          <Intro visible={ioInView} in={{ fadeLeft: 500 }} delayIn={950} mounted={true} stay={true} className="">
            <div className='single-school padd'>
              <div className='img'>
                <div className='aspect-ratio'>
                  <Img fluid={image?.[3]}
                    imgStyle={{ objectFit: 'cover' }}
                    objectPosition='50% 50%'
                    className='Hero Text'
                    alt='School of Nursing'
                  />
                </div>
              </div>
              <div className='content padd padd-top-off'>
                <h4>School of NUrsing</h4>
                <p className='fs-85'>Established in 2012, includes undergraduate programs for Associate and Bachelor’s degrees. Eligible graduates of these programs are prepared to take the National Council Licensure Examination (NCLEX-RN) and become licensed Registered Nurses.</p>
                <div className="links-container fs-85">
                  <Link to="/archives/academic/bachelor-of-science-in-nursing-rn-to-bsn" className='button'>BACHELOR OF SCIENCE IN NURSING (RN TO BSN)<span className="btn-arrow"></span></Link >
                  <Link to="/archives/academic/master-of-science-in-nursing-direct-entry" className='button'>MASTER OF SCIENCE IN NURSING DIRECT ENTRY<span className="btn-arrow"></span></Link >
                  <Link to="/archives/academic/family-nurse-practitioner-post-master-certificate-fnp-pmc" className='button'>FAMILY NURSE PRACTITIONER POST-MASTER CERTIFICATE<span className="btn-arrow"></span></Link >
                  <Link to="/archives/academic/bachelor-of-science-degree-in-nursing-bsn-2" className='button'>BACHELOR OF SCIENCE DEGREE IN NURSING (BSN)<span className="btn-arrow"></span></Link >
                  <Link to="/archives/academic/associate-of-science-degree-in-nursing2" className='button'>ASSOCIATE OF SCIENCE DEGREE IN NURSING<span className="btn-arrow"></span></Link >
                  <Link to="/archives/academic/family-nurse-practitioner2" className='button'>MASTER OF SCIENCE IN NURSING FAMILY NURSE PRACTITIONER<span className="btn-arrow"></span></Link >
                </div>
              </div>
            </div>
          </Intro>

        </div>
      </Intro>

    </div>
  )
}

export default CustomBlock