import React,{useEffect} from 'react'
import './style.scss'

import CF7Skill from '../../../../Form/CF7/Skill'
import CF7Activity from '../../../../Form/CF7/Activity'
import CF7Club from '../../../../Form/CF7/Club'

function CustomBlock({ fields: field }) {

  useEffect(()=>{
    let targets = document.querySelectorAll(`.form-container`)
    targets.forEach(target => target.style.setProperty('--originalHeight', `${target.scrollHeight}px`))
  }, [])

  const expand = (e) => {
    let forms = document.querySelectorAll(`.form-container`)
    let buttons = document.querySelectorAll(`.links-container`)
    let currentButton = e.currentTarget.children[0]
    let currentForm = e.currentTarget.children[1]
    
    forms.forEach(form => form.classList.remove('expand'))
    buttons.forEach(button => button.classList.remove('expand'))
    currentForm.classList.toggle('expand')
    currentButton.classList.toggle('expand')
  }

	return (
        <>
        <h2 className="title">Be involved</h2>
        <div onClick={(e) => expand(e)} aria-label='Expand Form' className="single-item">
          <div  className="links-container expand">
            <button className='button'>Share a Skill<span className="btn-arrow"></span></button >
          </div> 
          <div className="form-container  expand">
            <CF7Skill />
          </div>
        </div>
        <div onClick={(e) => expand(e)} aria-label='Expand Form' className="single-item">
          <div  className="links-container">
            <button className='button'>Suggest an Activity<span className="btn-arrow"></span></button >
          </div> 
          <div className="form-container">
            <CF7Activity />
          </div>
        </div>
        <div onClick={(e) => expand(e)} aria-label='Expand Form' className="single-item">
          <div  className="links-container">
            <button className='button'>Start a Club<span className="btn-arrow"></span></button >
          </div> 
          <div className="form-container">
            <CF7Club />
          </div>
        </div>
        </>
	)
}

export default CustomBlock