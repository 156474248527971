import './style.scss'
import { Link } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map, anchors: anchor}) {


  const items = anchor.map( (link,i) => {
    return (
      <div key={i} className="single-item grid-12">
        <div className="span-5 span-12-mobile img-wrapper">
            <Link to={link} title="school link">
              <div className="img-wrap">                 
                  <Img fluid={img[i]}
											imgStyle={{objectFit: 'cover'}}
											objectPosition='50% 50%'
											className='image-main'
											alt=''
							    />              
              </div>
            </Link>
        </div>
        <div className="content-box span-7 span-12-mobile">
            <div className={`wysiwyg-inject animated`}>
                <h2 className='h3'>{field[i]}</h2>
                <div dangerouslySetInnerHTML={{__html: wysiwyg[i]}} />
            </div>
            <Link className="btn" to={link}>
                <span className="button">Learn more</span>
            </Link>
        </div>
      </div>
    )
  
  } ) 

	return (
		<div className="master-wrap grid-12">
            <div className="span-10 span-12-mobile content">
              {items}
            </div>
            <div className="span-1 box">
                <div className="line"></div>
                <div className="nav"> 
                    <div className='nav-item span-12'>
                        <p>Choose a school</p>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default CustomBlock